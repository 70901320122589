<template>
  <div>
    <button @click="$router.go(-1)" class="btn btn-dark btn-sm mt-4 mb-1">
      Back
    </button>
    <div class="w-100">
      <product-details
        @change="fetch_product"
        :product="product"
      ></product-details>
    </div>
    <div class="w-100 p-3 pb-5 mb-4">
      <div class="mb-3" v-if="product_options.length <= 0">
        <label class="form-control-label"
          >Does your product have options?
          <em
            @click="alert_option_info"
            class="fas fa-info-circle hover-cursor"
          ></em>
        </label>
        <div class="col-md-4">
          <label class="custom-toggle text-white">
            <input
              id="change_visibility"
              type="checkbox"
              :checked="show_options"
              @change="(e) => (show_options = e.target.checked)"
            />
            <span
              class="custom-toggle-slider rounded-circle bg-default"
              data-label-off="No"
              data-label-on="Yes"
            ></span>
          </label>
        </div>
      </div>
      <!-- <h2 class="text-center h2">Let's add some varieties to your product</h2> -->
      <div
        class="row card shadow p-3"
        v-if="product_options.length > 0 || show_options"
      >
        <h3 class="h4 text-right col-12 mb-4">
          Manage options
          <em
            @click="alert_option_info"
            class="fas fa-info-circle hover-cursor fa-1x"
          ></em>
        </h3>
        <div class="col-md-4 ml-2">
          <text-input
            :value="optionTitle.value"
            :errors="optionTitle.errors"
            :label="optionTitle.label"
            :placeholder="optionTitle.placeholder"
            @type="(v) => ((optionTitle.value = v), saveOptionTitle(v))"
          ></text-input>
        </div>
        <!-- <div class="col-12 ml-2">
        <button
          class="btn btn-default btn-sm"
          type="button"
          data-toggle="collapse"
          data-target="#newoption"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Manage Options
        </button>
      </div> -->
        <div class="row w-100 col-md-12 p-4" id="newoption">
          <div class="row col-md-6">
            <div class="col-md-6">
              <text-input
                :value="option_post_data.name"
                :errors="option_errors.name"
                :label="name.label"
                :placeholder="name.placeholder"
                @type="(v) => (option_post_data.name = v)"
                :required="true"
              ></text-input>
            </div>
            <div class="col-md-6">
              <number-input
                :value="option_post_data.price"
                :errors="option_errors.price"
                :label="price.label"
                :placeholder="price.placeholder"
                @type="(v) => (option_post_data.price = v)"
                :required="true"
              ></number-input>
            </div>
            <div class="col-md-12">
              <label class="form-control-label">Picture:</label>
              <br v-if="option_picture_update" />
              <a target="blank" :href="backend_url + option_picture_update"
                ><span
                  class="text-default text-sm"
                  v-if="option_picture_update"
                  >{{ backend_url + option_picture_update }}</span
                ></a
              >
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFileLang"
                  lang="en"
                  v-show="false"
                  @change="process_file('picture', $event)"
                />
                <label class="custom-file-label p-3" for="customFileLang">
                  <span v-if="optionImage.url" class="avatar h-75">
                    <img alt="Image placeholder" :src="optionImage.url" />
                  </span>
                  <span class="ml-3 mt--3">{{ optionImage.name }}</span>
                </label>
              </div>
            </div>
            <div class="col-md-12">
              <button
                @click="create_or_update_option(option_post_data.update_uuid)"
                class="btn float-right btn-default mt-4"
              >
                {{ option_post_data.update_uuid ? "Update" : "Add" }}
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="h3">Existing options</h3>
            <table class="table align-items-center">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Option</th>
                  <th scope="col">Price</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr :key="option.uuid" v-for="option in product_options">
                  <th scope="row">
                    <div class="media align-items-center">
                      <a href="#" class="avatar rounded-circle mr-3">
                        <img
                          :onerror="`this.src='${$img_placeholder}';`"
                          alt=""
                          :src="backend_url + option.picture"
                        />
                      </a>
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{ option.name }}</span>
                      </div>
                    </div>
                  </th>
                  <td>{{ $num_format(option.price) }}</td>
                  <td>
                    <button
                      @click="update_option(option)"
                      class="btn btn-icon btn-primary btn-sm"
                    >
                      <em class="fas fa-pencil-alt"></em>
                    </button>
                    <button
                      @click="delete_option(option.uuid)"
                      class="btn btn-icon btn-danger btn-sm"
                    >
                      <em class="fas fa-trash-alt"></em>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="product_choices.length <= 0">
        <label class="form-control-label"
          >Does your product have choices?
          <em
            @click="alert_choice_info"
            class="fas fa-info-circle hover-cursor"
          ></em>
        </label>
        <div class="col-md-4">
          <label class="custom-toggle text-white">
            <input
              id="change_visibility"
              type="checkbox"
              :checked="show_choices"
              @change="(e) => (show_choices = e.target.checked)"
            />
            <span
              class="custom-toggle-slider rounded-circle bg-default"
              data-label-off="No"
              data-label-on="Yes"
            ></span>
          </label>
        </div>
      </div>
      <div
        class="row p-3 card shadow mt-4"
        v-if="show_choices || product_choices.length > 0"
      >
        <h3 class="h4 text-right col-12 mb-4">
          Manage Choices
          <em
            @click="alert_choice_info"
            class="fas fa-info-circle hover-cursor"
          ></em>
        </h3>
        <div class="col-md-4 ml-2">
          <text-input
            :value="choiceTitle.value"
            :errors="choiceTitle.errors"
            :label="choiceTitle.label"
            :placeholder="choiceTitle.placeholder"
            @type="(v) => ((choiceTitle.value = v), saveChoiceTitle(v))"
          ></text-input>
        </div>
        <!-- <div class="col-12 ml-2">
        <button
          class="btn btn-default btn-sm"
          type="button"
          data-toggle="collapse"
          data-target="#choices"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Manage Choices
        </button>
      </div> -->
        <div class="row w-100 col-md-12 p-4" id="choices">
          <div class="row col-md-6">
            <h3 class="h3 col-12 mb-3">New Choice</h3>
            <div class="col-md-6">
              <text-input
                :value="choice_post_data.name"
                :errors="choice_errors.name"
                label="Name:"
                placeholder="Enter the name of the choice (eg. blue)"
                @type="(v) => (choice_post_data.name = v)"
              ></text-input>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label"
                  >Reference
                  <em
                    @click="alert_ref_info"
                    class="fas fa-info-circle hover-cursor"
                  ></em
                ></label>
                <select
                  v-model="choice_post_data.uuid"
                  class="form-control form-control-alternative"
                >
                  <option :value="$route.params.uuid">
                    The Product itself
                  </option>
                  <option
                    v-for="option in product_options"
                    :key="option.uuid"
                    :value="option.uuid"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <label
                  v-for="error in choice_errors.uuid"
                  :key="error"
                  class="form-control-flush text-danger text-sm"
                  >{{ error }}</label
                >
              </div>
            </div>
            <div class="col-md-12">
              <label class="form-control-label">Picture:</label>
              <br v-if="choice_picture_update" />
              <a target="blank" :href="backend_url + choice_picture_update"
                ><span
                  class="text-default text-sm"
                  v-if="choice_picture_update"
                  >{{ backend_url + choice_picture_update }}</span
                ></a
              >
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFileLang1"
                  lang="en"
                  v-show="false"
                  @change="process_choicefile('picture', $event)"
                />
                <label class="custom-file-label p-3" for="customFileLang1">
                  <span v-if="choiceImage.url" class="avatar h-75">
                    <img alt="Image placeholder" :src="choiceImage.url" />
                  </span>
                  <span class="ml-3 mt--3">{{ choiceImage.name }}</span>
                </label>
              </div>
            </div>
            <div class="col-md-12">
              <button
                @click="create_or_update_choice(choice_post_data.update_uuid)"
                class="btn float-right btn-default mt-4"
              >
                {{ choice_post_data.update_uuid ? "Update" : "Add" }}
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="h3 mb-3">Existing Choices</h3>
            <table class="table align-items-center">
              <thead class="thead-light">
                <tr>
                  <th scope="col">choice</th>
                  <th scope="col">Reference</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr :key="choice.uuid" v-for="choice in product_choices">
                  <th scope="row">
                    <div class="media align-items-center">
                      <a href="#" class="avatar rounded-circle mr-3">
                        <img
                          :onerror="`this.src='${$img_placeholder}';`"
                          alt=""
                          :src="backend_url + choice.picture"
                        />
                      </a>
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{ choice.name }}</span>
                      </div>
                    </div>
                  </th>
                  <td>{{ choice.referenceName }}</td>
                  <td>
                    <button
                      @click="update_choice(choice)"
                      class="btn btn-icon btn-primary btn-sm"
                    >
                      <em class="fas fa-pencil-alt"></em>
                    </button>
                    <button
                      @click="delete_choice(choice.uuid)"
                      class="btn btn-icon btn-danger btn-sm"
                    >
                      <em class="fas fa-trash-alt"></em>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="price_ranges.length <= 0">
        <label class="form-control-label"
          >Does your product have price ranges?
          <!-- <em class="fas fa-info-circle hover-cursor"></em> -->
        </label>
        <div class="col-md-4">
          <label class="custom-toggle text-white">
            <input
              id="change_visibility"
              type="checkbox"
              :checked="show_ranges"
              @change="(e) => (show_ranges = e.target.checked)"
            />
            <span
              class="custom-toggle-slider rounded-circle bg-default"
              data-label-off="No"
              data-label-on="Yes"
            ></span>
          </label>
        </div>
      </div>
      <div
        class="row p-3 card shadow mt-4"
        v-if="price_ranges.length > 0 || show_ranges"
      >
        <h3 class="h4 text-right col-12 mb-4">Manage Price ranges</h3>
        <div class="row w-100 col-md-12 p-4" id="choices">
          <div class="row col-md-6">
            <h3 class="h3 col-12 mb-3">New Price Range</h3>
            <div class="col-md-6">
              <number-input
                :value="pricerange_post_data.minimum_quantity"
                :errors="pricerange_errors.minimum_quantity"
                label="Minimum Quantity:"
                placeholder="Enter the minimum quantiy"
                @type="(v) => (pricerange_post_data.minimum_quantity = v)"
              ></number-input>
            </div>
            <div class="col-md-6">
              <number-input
                :value="pricerange_post_data.maximum_quantity"
                :errors="pricerange_errors.maximum_quantity"
                label="Maximum Quantity:"
                placeholder="Enter the maximum quantiy"
                @type="(v) => (pricerange_post_data.maximum_quantity = v)"
              ></number-input>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label"
                  >Reference
                  <em
                    @click="alert_ref_info_price"
                    class="fas fa-info-circle hover-cursor"
                  ></em
                ></label>
                <select
                  v-model="pricerange_post_data.uuid"
                  class="form-control form-control-alternative"
                >
                  <option
                    v-if="product_options.length === 0"
                    :value="$route.params.uuid"
                  >
                    The Product itself
                  </option>
                  <option
                    v-for="option in product_options"
                    :key="option.uuid"
                    :value="option.uuid"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <span class="text-muted text-sm"></span>
              </div>
            </div>
            <div class="col-md-6">
              <number-input
                :value="pricerange_post_data.price"
                :errors="pricerange_errors.price"
                label="Price:"
                placeholder="Enter the price"
                @type="(v) => (pricerange_post_data.price = v)"
              ></number-input>
            </div>
            <div class="col-md-12">
              <button
                @click="
                  create_or_update_price_range(pricerange_post_data.update_uuid)
                "
                class="btn float-right btn-default mt-4"
              >
                {{ pricerange_post_data.update_uuid ? "Update" : "Submit" }}
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="h3 mb-3">Existing Price Ranges</h3>
            <table
              class="table align-items-center"
              aria-describedby="price_ranges"
            >
              <thead class="thead-light">
                <tr>
                  <th scope="col">Minimum Quantity</th>
                  <th scope="col">Maximun Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Reference</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr :key="range.uuid" v-for="range in price_ranges">
                  <td>{{ range.minimum_quantity }}</td>
                  <td>{{ range.maximum_quantity }}</td>
                  <td>{{ range.price }}</td>
                  <td>{{ range.referenceName }}</td>
                  <td>
                    <button
                      @click="update_price_range(range)"
                      class="btn btn-icon btn-primary btn-sm"
                    >
                      <em class="fas fa-pencil-alt"></em>
                    </button>
                    <button
                      @click="delete_price_range(range.uuid)"
                      class="btn btn-icon btn-danger btn-sm"
                    >
                      <em class="fas fa-trash-alt"></em>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="pb-5">
        <button
          @click="
            goto(
              $router.resolve({
                name: 'Product Details',
                params: { uuid: $route.params.uuid },
              }).href
            )
          "
          class="float-left mt-4 btn btn-md btn-secondary"
        >
          <em class="fas fa-eye"></em>
          Preview
        </button>
        <button
          @click="$router.push({ name: 'Products' })"
          class="float-right mt-4 btn btn-md btn-default"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TextInput from "@/components/TextInput";
import NumberInput from "../../components/NumberInput";
import { alert } from "../../utils/alertUtils";
import ProductDetails from "@/components/BasicProductDetails";

export default {
  name: "ProductGallery",
  components: {
    // "drop-zone":Dropzone
    "text-input": TextInput,
    "number-input": NumberInput,
    "product-details": ProductDetails,
  },
  data() {
    return {
      backend_url: process.env.VUE_APP_BACKEND_URL,
      optionTitle: {
        value: "",
        errors: [],
        label: "Option Label:",
        placeholder:
          "Enter the Option Title of the product (eg. 'choose a shape')",
      },
      choiceTitle: {
        value: "",
        errors: [],
        label: "Choice Label:",
        placeholder: "Enter the Choice Title of the product (eg. 'color')",
      },
      name: {
        errors: [],
        label: "Name:",
        placeholder: "Enter the name of the option (eg. 'round')",
      },
      price: {
        errors: [],
        label: "Price:",
        placeholder: "Enter the price",
      },
      option_post_data: {},
      optionImage: {},
      product_options: [],
      choice_post_data: {},
      product_choices: [],
      choiceImage: {},
      optionTimeOutId: "",
      choiceTimeOutId: "",
      product: {},
      pricerange_post_data: {},
      pricerange_errors: {},
      price_ranges: [],
      option_picture_update: "",
      choice_picture_update: "",
      option_errors: {},
      choice_errors: {},
      show_options: false,
      show_choices: false,
      show_ranges: false,
    };
  },
  methods: {
    update_option: function (option) {
      this.option_post_data.name = option.name;
      this.option_post_data.price = option.price;
      this.option_picture_update = option.picture;
      this.optionImage.url = this.backend_url + option.picture;
      this.option_post_data.update_uuid = option.uuid;
    },
    update_choice: function (choice) {
      this.choice_post_data.name = choice.name;
      this.choice_post_data.uuid = choice.is_generic
        ? this.product.uuid
        : this.product_options.find((op) => op.id == choice.referenceId).uuid;
      this.choice_picture_update = choice.picture;
      this.choiceImage.url = this.backend_url + choice.picture;
      this.choice_post_data.update_uuid = choice.uuid;
    },
    update_price_range: function (price_range) {
      this.pricerange_post_data.minimum_quantity = price_range.minimum_quantity;
      this.pricerange_post_data.maximum_quantity = price_range.maximum_quantity;
      this.pricerange_post_data.uuid = price_range.is_generic
        ? this.product.uuid
        : this.product_options.find((op) => op.id == price_range.referenceId)
            .uuid;
      this.pricerange_post_data.price = price_range.price;
      this.pricerange_post_data.update_uuid = price_range.uuid;
    },
    process_file: function (name, e) {
      this.option_post_data[name] = e.target.files[0];
      this.optionImage.url = URL.createObjectURL(e.target.files[0]);
      this.optionImage.name = e.target.files[0].name;
      console.log(e.target.files[0]);
    },
    process_choicefile: function (name, e) {
      this.choice_post_data[name] = e.target.files[0];
      this.choiceImage.url = URL.createObjectURL(e.target.files[0]);
      this.choiceImage.name = e.target.files[0].name;
      console.log(e.target.files[0]);
    },
    create_or_update_option: function (uuid) {
      this.option_errors = {};
      let formdata = new FormData();
      for (const key in this.option_post_data) {
        formdata.append(key, this.option_post_data[key]);
      }
      formdata.append("product_id", this.$route.params.uuid);
      if (uuid) {
        axios
          .put(
            `${process.env.VUE_APP_BACKEND_URL}/api/productOptions/${uuid}`,
            formdata,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.fetch_existing_options();
            this.option_post_data = {};
            this.optionImage = {};
            this.option_picture_update = "";
            alert.success_center("Option Updated successfully");
          })
          .catch((err) => {
            console.error(err.response);
            if (err.response) {
              if (err.response.status == 400) {
                this.option_errors = err.response.data;
              }
            }
          });
      } else {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_URL}/api/productOptions`,
            formdata,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.fetch_existing_options();
            this.option_post_data = {};
            this.optionImage = {};
            alert.success_center("Option created successfully");
          })
          .catch((err) => {
            console.error(err.response);
            if (err.response) {
              if (err.response.status == 400) {
                this.option_errors = err.response.data;
              }
            }
          });
      }
    },
    create_or_update_choice: function (uuid) {
      this.choice_errors = {};
      let formdata = new FormData();
      for (const key in this.choice_post_data) {
        if (key == "uuid") {
          if (this.choice_post_data[key] == this.product.uuid) {
            formdata.append("is_generic", true);
          } else {
            formdata.append("is_generic", false);
          }
        }
        formdata.append(key, this.choice_post_data[key]);
      }
      for (var pair of formdata.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      if (uuid) {
        axios
          .put(
            `${process.env.VUE_APP_BACKEND_URL}/api/productChoices/${uuid}`,
            formdata,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
          )
          .then(() => {
            this.choice_post_data = {};
            this.choiceImage = {};
            this.choice_picture_update = "";
            alert.success_center("Choice Updated successfully");
            this.fetch_existing_choices();
          })
          .catch((err) => {
            console.error(err.response);
            if (err.response) {
              if (err.response.status == 400) {
                this.choice_errors = err.response.data;
              }
            }
          });
      } else {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_URL}/api/productChoices`,
            formdata,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
          )
          .then(() => {
            this.choice_post_data = {};
            this.choiceImage = {};
            alert.success_center("Choice created successfully");
            this.fetch_existing_choices();
          })
          .catch((err) => {
            console.error(err.response);
            if (err.response) {
              if (err.response.status == 400) {
                this.choice_errors = err.response.data;
              }
            }
          });
      }
    },
    create_or_update_price_range: function (uuid) {
      this.pricerange_post_data.is_generic =
        this.pricerange_post_data.uuid === this.product.uuid;
      if (uuid) {
        axios
          .put(
            `${process.env.VUE_APP_BACKEND_URL}/api/price_ranges/${uuid}`,
            this.pricerange_post_data,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
          )
          .then(() => {
            this.pricerange_post_data = {};
            alert.success_center("Range updated successfully");
            this.fetch_existing_ranges();
          })
          .catch((err) => {
            console.error(err.response);
          });
      } else {
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_URL}/api/price_ranges`,
            this.pricerange_post_data,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.getToken,
              },
            }
          )
          .then(() => {
            this.pricerange_post_data = {};
            alert.success_center("Range created successfully");
            this.fetch_existing_ranges();
          })
          .catch((err) => {
            console.error(err.response);
          });
      }
    },
    fetch_existing_options: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.$route.params.uuid}/all/Options`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.product_options = res.data.productOptions;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetch_existing_choices: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.$route.params.uuid}/all/Choices`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.product_choices = res.data.productChoices;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetch_existing_ranges: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.$route.params.uuid}/all/price_ranges`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.price_ranges = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    delete_option: function (uuid) {
      axios
        .delete(
          `${process.env.VUE_APP_BACKEND_URL}/api/productOptions/${uuid}`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          alert.success_center("option deleted successfully");
          this.fetch_existing_options();
          this.fetch_existing_choices();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    delete_choice: function (uuid) {
      axios
        .delete(
          `${process.env.VUE_APP_BACKEND_URL}/api/productChoices/${uuid}`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          alert.success_center("Choice deleted successfully");
          this.fetch_existing_choices();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    delete_price_range: function (uuid) {
      axios
        .delete(`${process.env.VUE_APP_BACKEND_URL}/api/price_ranges/${uuid}`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res);
          alert.success_center("Price Range deleted successfully");
          this.fetch_existing_ranges();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    goto: function (link) {
      window.open(link, "_blank");
    },
    saveTitle: function (option, v) {
      const post_data = {
        Option: option,
        title: v,
      };
      axios
        .put(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/${this.$route.params.uuid}/Titles/Update`,
          post_data,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          return true;
        })
        .catch((err) => {
          console.error(err);
          return false;
        });
    },
    saveOptionTitle: function (v) {
      clearTimeout(this.optionTimeOutId);
      this.optionTimeOutId = setTimeout(() => {
        this.saveTitle("Option", v);
      }, 2000);
    },
    saveChoiceTitle: function (v) {
      clearTimeout(this.choiceTimeOutId);
      this.choiceTimeOutId = setTimeout(() => {
        this.saveTitle("Choice", v);
      }, 2000);
    },
    fetch_product: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/detail/${this.$route.params.uuid}`
        )
        .then((res) => {
       
          this.product = res.data.product;
          this.optionTitle.value = this.product.OptionTitle;
          this.choiceTitle.value = this.product.choiceTitle;
        })
        .catch((err) => {
         if(err.response.status===403){
              alert.error("Session Expired, Please Login again");
              this.$router.push({ name: "Login",})
            }
        });
    },
    alert_ref_info: function () {
      alert.info_center(
        "A reference can either be the product itself or one of the product's options.\n This means that a choice will always be available in case the reference is the product, in case the reference is one of the options, the choice will only show when a customer picks that option"
      );
    },
    alert_ref_info_price: function () {
      alert.info_center(
        "A reference can either be the product itself or one of the product's options.\n This means that this price range will always be available in case the reference is the product, in case the reference is one of the options, the price range will only show when a customer picks that option"
      );
    },
    alert_option_info: function () {
      let info =
        '<h3>What is a product option?</h3><p class="text-justify"> <span class="font-weight-bold"> A Product option </span> is a variant of the product which mostly influences the price of the product.</p><h4 class="text-left">Example:</h4> <p class="text-justify">If We take an example of <span class="font-weight-bold">a basket</span>, options could be sizes <span class="font-weight-bold">Small, Medium, Large</span>.Technically the size will influence the price of that basket.</p><h3>So what is an option Label?</h3><p class="text-justify"><span class="font-weight-bold">An option label</span> is a message that instructs the customers about the options they have to choose from. From our example above the <span class="font-weight-bold">option label</span> would be <span class="font-weight-bold">"Pick a size".</span></p><p class="text-justify font-weight-bold"><em class="fas fa-exclamation-triangle fa-2x"></em> Options will influence the price. If your variants don\'t influence the price then take a look at product choices.</p>';
      alert.info_center_html(info);
    },
    alert_choice_info: function () {
      let info =
        '<h3>What is a product choice?</h3><p class="text-justify"> <span class="font-weight-bold">A Product choice</span> is a variant of the product which doesn\'t influence the price of the product.</p><h4 class="text-left">Example:</h4> <p class="text-justify">If We take an example of <span class="font-weight-bold">a basket</span>, choices could be colors <span class="font-weight-bold">Green, Red, Blue</span>.In most cases, the color will not influence the price of a basket.</p><h3>So what is a choice Label?</h3><p class="text-justify"><span class="font-weight-bold">A choice label</span> is a message that instructs the customers about the choices they have. From our example above the <span class="font-weight-bold">choice label</span> would be <span class="font-weight-bold">"Select a color".</span></p><p class="text-justify font-weight-bold"><em class="fas fa-exclamation-triangle fa-2x"></em> Choices will not influence the price. If your variants influences the price then make them product options.</p>';
      alert.info_center_html(info);
    },
  },
  created() {
    this.fetch_existing_options();
    this.fetch_existing_choices();
    this.fetch_existing_ranges();
    this.fetch_product();
  },
};
</script>

<style>
</style>