<template>
  <div class="card shadow w-100 p-4">
    <h4 class="text-right">Basic info</h4>
    <div class="row">
      <div class="col-md-4">
        <div>
          <label class="form-control-label">{{ product.name }}</label> <br />
          <img
         
            class="img-fluid"
            style="height: 200px"
            v-if="product.primeImageUrl"
            :src="root_img_url+product.primeImageUrl"
            alt=""
          />
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <label class="form-control-label">Description</label> <br />
          <span
            class="text-muted"
            v-if="product.description"
            v-html="
              product.description.length > 150
                ? product.description.substring(0, 150) + '...'
                : product.description
            "
          ></span>
        </div>
        <div class="mt-3">
          <label class="form-control-label">Price</label> <br />
          <span class="text-darker text-md">
            <em class="fas mr-1 fa-tag"></em>
            <span v-if="product.discount"
              >{{ $num_format(get_price) }} {{ product.currency}}</span
            >
            <span :class="product.discount ? 'text-cross ml-2 text-muted' : ''"
              >{{ $num_format(product.price) }} {{ product.currency}}</span
            >
            <span
              v-if="product.discount"
              class="badge-warning badge badge-pill ml-2"
              >{{ product.discount }}% off</span
            >
          </span>
        </div>
        <div>
           <label class="form-control-label">Categories</label><br />
           <span v-for="category in product.categories" :key="category" class="badge badge-dot m-1">{{ category }}, </span>
        </div>

      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: "Basic Product Details",
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    get_price() {
      if (this.product.discount) {
        return (
          this.product.price -
          this.product.price * (this.product.discount / 100)
        );
      }
      return this.product.price;
    },
  },
  data() {
    return {
      backend_url: process.env.VUE_APP_BACKEND_URL,
      root_img_url:process.env.VUE_APP_IMG_URL,
    };
  },
  methods: {



  },
  created(){
    console.log(this.product)
  }
};
</script>

<style scoped>
.text-cross {
  text-decoration: line-through;
}
</style>